/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import Spinner from "./Spinner";
import { stripHrefDomain, sendClevertapEvent } from "../helpers/misc";
import theme, { colors } from "../styles/common";
import { buyButtonColorStyle, fontStyle } from "./EDPStyles";
import { ACCESSIBLITY_TALKBACK_PHRASES, CLEVERTAP_ACTIONS, EVENT_GROUP_ID, getComputedState, SCREENING_CATEGORY_ID, SPORTS_GROUP_ID } from "../helpers/constants";
import { useContext, useEffect, useState } from "react";
import EDPBuyContext from "../contexts/EDPBuyContext";
import { DATA_REFS } from "../helpers/constants/dataRefs";
import queryString from "query-string";
import { generateEventBuyLink } from "../helpers/linkGenerators";
import { fetchNewBuyFlowEventIds } from "../helpers/apiClient";
import { includes } from "lodash";
import BottomSheetModal from "./BottomSheetModal";
import { PrimaryButton } from "./ui-lib/Atoms/Buttons/styles";
import { BaseBold, FontColor, SmallMedium } from "./ui-lib/Atoms/Typography/styles";
import { TicketsAtVenueModal } from "./BuyFlow/utils";
const getEventDetailPayload = (eventData, selectedCity, queryParams) => {
    return Object.assign({ "Event ID": eventData.get("_id"), City: selectedCity, Price: eventData.get("price_display_string"), Category: eventData.getIn(["category_id", "name"]), "Category ID": eventData.getIn(["category_id", "id"]), Group: eventData.getIn(["group_id", "name"]), "Group ID": eventData.getIn(["group_id", "id"]), "Venue Tag IDs": eventData.getIn(["venue", "id", "_id"]), "Venue Name": eventData.getIn(["venue", "id", "name"]), "Brand ID": eventData.getIn(["brand_id", "_id"]), "Brand Name": eventData.getIn(["brand_id", "name"]), "Event City": eventData.get("city"), "Event Name": eventData.get("name"), URL: window.location.href, Slug: eventData.get("slug"), "Event State": getComputedState(eventData), "Event Image": eventData.get("horizontal_cover_image") }, (Object.keys(queryParams).length > 0 && {
        utm_source: queryParams.utm_source,
        utm_content: queryParams.utm_content
    }));
};
const ChildButton = (props) => {
    let { children, onClick, loading, disabled, href, hrefTarget, hrefRel, isFromApp, selected_city, eventData, queryParams, dataRef, showWarning, shouldShowWarningPopup, showVenuePopup, isExternalBuyLink = false } = props;
    if (loading) {
        children = <Spinner />;
        onClick = async (e) => e.preventDefault();
    }
    const buttonStyle = `
		width: 100%;
		color: white;
		font-weight: bold;
		text-align: center;
		font-size: 21px;
		padding: 8px 18px;
		max-height: 42px;
		text-transform: uppercase;
		white-space: nowrap;
		${theme.mediaQueries["for-mobile"]} {
			font-size: 24px;
			padding: 6px 24px;
		};
		${theme.mediaQueries["for-tablet-and-pc"]} {
			font-size: 16px;
			padding: 8px;
		};
		border-radius: 6px;
		cursor: pointer;
	`;
    const disabledStyle = `
		background-color:${colors.disabledButton};
		cursor: inherit;
	`;
    useEffect(() => {
        if (props.shouldRedirectToBuy) {
            redirectToBuy();
        }
    }, [props.shouldRedirectToBuy]);
    const redirectToBuy = async (e) => {
        const { platform, version } = queryString.parse(window.location.search);
        // fetch new href link for certain events
        let shouldShowReactBuyPage = true;
        let oldFlowEventIds = await fetchNewBuyFlowEventIds();
        oldFlowEventIds = oldFlowEventIds.toJS();
        if (includes(oldFlowEventIds, eventData.get("_id").toString())) {
            shouldShowReactBuyPage = false;
        }
        let href = await generateEventBuyLink(eventData.get("slug"), platform, shouldShowReactBuyPage);
        // End of custom code
        sendClevertapEvent(CLEVERTAP_ACTIONS.BUY_NOW_CTA_BUTTON, getEventDetailPayload(eventData, selected_city, queryParams));
        // Open old buy flow for android app version 23.6.10
        // This is to trigger the UPI Plugin on payments page
        if (platform === "android" && version === "23.6.10") {
            e.preventDefault();
            props.openAppBuyButton();
        }
        if (onClick) {
            // trigger onClick coming from RemindMeActionButton
            onClick(e);
        }
        window.location.href = href;
    };
    const checkIfScreeningEvent = () => {
        const categoryId = eventData.getIn(["category_id", "id"]);
        if (categoryId.toString() === SCREENING_CATEGORY_ID)
            return true;
        return false;
    };
    const handleClick = async (e) => {
        e.preventDefault();
        if (checkIfScreeningEvent() && !props.shouldRedirectToBuy) {
            showWarning();
            return;
        }
        if (eventData.get("event_state") === "tickets_at_venue" && !props.shouldRedirectToBuy) {
            showVenuePopup();
        }
        else {
            redirectToBuy(e);
        }
    };
    if (!disabled) {
        return (<a css={[css(buttonStyle), buyButtonColorStyle, fontStyle]} target={hrefTarget} href={href} rel={hrefRel} children={children} onClick={e => {
                if (!isExternalBuyLink)
                    handleClick(e);
            }} data-ref={dataRef}/>);
    }
    return <button children={children} css={[css(buttonStyle), css(disabledStyle), fontStyle]} data-ref={dataRef}/>;
};
const EDPBuyButton = (props) => {
    var _a;
    let { text, link, disabled, loading, htmlCaption, href, hrefTarget, hrefRel, onClick, name, slug, price_display_string, isFree, type, isReminderButton, isFromApp, openAppBuyButton, skipContentBeforeRSVPButton, eventData, selected_city, queryParams, shouldBlockiOSPurchase, isExternalBuyLink = false, shouldDisableBuyButton = false } = props;
    const { displayMode } = useContext(EDPBuyContext);
    const [shouldShowWarning, setshouldShowWarning] = useState(false);
    const [shouldRedirectToBuy, setshouldRedirectToBuy] = useState(false);
    const [showTicketsAtVenueModal, setShowTicketsAtVenueModal] = useState(false);
    useEffect(() => {
        function handlePageShow(event) {
            if (event.persisted) {
                // The page was loaded from the bfcache(broswer optimise techique)
                setshouldRedirectToBuy(false); // Reset state to initial value
            }
        }
        window.addEventListener("pageshow", handlePageShow);
        // Clean up event listener on unmount
        return () => window.removeEventListener("pageshow", handlePageShow);
    }, []);
    const buyButtonLayoutStyle = `
		alignSelf: flex-end;
		display: flex;
		background-color: white;
		z-index: 10;
		${theme.mediaQueries["for-mobile-and-tablet"]}{
			display: ${type === "top" ? "none" : "flex"};
			position: sticky;
			bottom: 0;
			box-shadow: 0 -2px 12px 0 rgba(0, 0, 0, 0.1);
			padding: 12px 6vw;
		};
		${theme.mediaQueries["for-pc"]}{
			display: ${type === "bottom" ? "none" : "flex"};
		};
	`;
    const buyButtonPriceLayout = `
		display: block;
		flex: 1;
		padding: 0px;
		margin: auto;
		${theme.mediaQueries["for-pc"]} {
			flex: 5;
		};
	`;
    const IconStyle = `
			display: flex;
			align-items: center;
			margin-right: 12px;
		`;
    const priceDescStyle = `
		font-size: 12px;
		line-height: 1.17;
		color: ${colors.subTextGrey};
		${"& a"}{
			color: ${colors.primaryBlue};
		}; 
	`;
    const Container = `
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 15px;
	flex: 1 0 0;
	`;
    const marginTitle = `
		margin: 4px 0;
		width: 90%;
	`;
    const confirmWarning = () => {
        setshouldRedirectToBuy(true);
        setshouldShowWarning(false);
    };
    const showWarning = () => {
        setshouldShowWarning(true);
    };
    const warningModalText = () => {
        const groupId = eventData.getIn(["group_id", "id"], "");
        if (groupId.toString() === EVENT_GROUP_ID) {
            //event group
            return `Please note, the tickets you are going to purchase are for a screening event.`;
        }
        else if (groupId.toString() === SPORTS_GROUP_ID) {
            //Sports group
            return `IMPORTANT: THIS IS NOT A VALID MATCH TICKET.`;
        }
        return ``;
    };
    const showVenuePopup = () => {
        setShowTicketsAtVenueModal(true);
    };
    return (<div css={css(buyButtonLayoutStyle)} aria-label={text + "button" + ACCESSIBLITY_TALKBACK_PHRASES.BUTTON} role="region">
			{shouldShowWarning && (<BottomSheetModal handleDismiss={() => {
                setshouldShowWarning(false);
                setshouldRedirectToBuy(false);
            }}>
					<div css={css(Container)}>
						<p css={css([BaseBold, FontColor("--grey-highlight-copy"), marginTitle])}>{warningModalText()}</p>
						<p css={css([SmallMedium, FontColor("--grey-regular-copy")])}>
							Please read through the screening event details including the T&Cs carefully. Tickets once bought for the
							screening event cannot be refunded.
						</p>
						<button css={css([PrimaryButton, `margin-top: 15px;`])} onClick={confirmWarning}>
							CONTINUE
						</button>
					</div>
				</BottomSheetModal>)}
			{showTicketsAtVenueModal && (<TicketsAtVenueModal onDismiss={() => {
                setShowTicketsAtVenueModal(false);
                setshouldRedirectToBuy(false);
            }} venue={(_a = eventData === null || eventData === void 0 ? void 0 : eventData.getIn(["venue", "id"])) === null || _a === void 0 ? void 0 : _a.toJS()}/>)}
			{!skipContentBeforeRSVPButton && (<div css={css(buyButtonPriceLayout)}>
					<div css={{ display: "flex", paddingRight: 9 }}>
						{/* {!isReminderButton && (
                <div css={css(IconStyle)}>
                    <PriceIcon />
                </div>
            )} */}
						<div css={{ display: "block", margin: "auto 0" }}>
							<p css={{ fontWeight: 700, fontSize: 19, lineHeight: "22px" }} data-ref={`${DATA_REFS.EDP_PRICE_STRING}_${displayMode}`}>
								{!isReminderButton && !isFree && <span>&#x20B9;</span>}
								{price_display_string}
							</p>
							<p css={css(priceDescStyle)} dangerouslySetInnerHTML={{ __html: htmlCaption }}/>
						</div>
					</div>
				</div>)}
			<div css={{
            display: "flex",
            alignItems: "center",
            flex: 1,
            borderRadius: "6px",
            [theme.mediaQueries["for-pc"]]: { flex: 3 }
        }}>
				<ChildButton href={stripHrefDomain(href)} disabled={disabled || shouldBlockiOSPurchase} loading={loading} hrefTarget={hrefTarget} hrefRel={hrefRel} onClick={onClick} isFromApp={isFromApp} openAppBuyButton={openAppBuyButton} eventData={eventData} selected_city={selected_city} queryParams={queryParams} dataRef={`${DATA_REFS.EDP_BUY_BUTTON}_${displayMode}`} showWarning={showWarning} shouldRedirectToBuy={shouldRedirectToBuy} showVenuePopup={showVenuePopup} isExternalBuyLink={isExternalBuyLink}>
					{text}
				</ChildButton>
			</div>
		</div>);
};
export default EDPBuyButton;
