/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { generateGoogleMapsLink } from "../helpers/linkGenerators";
// import { colors } from "../styles/common";
// import RichTextTitle from "./RichTextTitle";
import { openInBrowserForApp } from "../helpers/appBridge";
import { LinkWithH5Support } from "./LinkWithH5Support";
import { BackgroundColor, flexBox, FontColor, getFont, titleDefaultStyle } from "./ui-lib/Atoms/Typography/stylesUtils";
import PhysicalVenueIcon from "./ui/icons/EDPIcons/PhysicalVenueIcon";
import { colors, Display, Uppercase } from "./ui-lib/Atoms/Typography/styles";
import { mediaFeatures } from "../styles/common";
const VenueCard = ({ latitude, longitude, name, address, header, isFromApp }) => {
    // this is venue to be announced state
    // todo replace with a nicer venue coming soon component
    // We do a !latitude here to match latitude = 0 (coming soon venue)
    // and to match where venue itself is undefined (when the event has expired)
    if (!latitude || !longitude) {
        return null;
    }
    const googleMapsLink = generateGoogleMapsLink({ latitude, longitude });
    return (<div css={css([Venuecont])}>
			<div css={css([titleCont])}>
				<h6 css={css([titleDefaultStyle])}>{header}</h6>
			</div>
			<div css={css([venueBodyCont])}>
				<div css={css([venueAddress])}>
					<p css={css([mainText])}>{name}</p>
					<p>{address}</p>
				</div>
				<LinkWithH5Support 
    // css={{ ...directionButtonStyle }}
    target="_blank" href={googleMapsLink} onClick={e => {
            if (isFromApp) {
                e.preventDefault();
                openInBrowserForApp(googleMapsLink);
            }
        }}>
					<div css={css([btnCont])}>
						<PhysicalVenueIcon />
						<p>Get Directions</p>
					</div>
				</LinkWithH5Support>
			</div>
		</div>
    // <section id="section-venue" className="venue-wrapper">
    // 	{/*<span style="display: none;" data-sj-meta-lat=""></span>*/}
    // 	{/*<span style="display: none;" data-sj-meta-lng="{{longitude}}"></span>*/}
    // 	<section className="text text-align-left custom-widget-title">
    // 		<RichTextTitle text={header} />
    // 		<h3>{name}</h3>
    // 		<p>{address}</p>
    // 		<LinkWithH5Support
    // 			css={{ ...directionButtonStyle }}
    // 			target="_blank"
    // 			href={googleMapsLink}
    // 			onClick={e => {
    // 				if (isFromApp) {
    // 					e.preventDefault();
    // 					openInBrowserForApp(googleMapsLink);
    // 				}
    // 			}}
    // 		>
    // 			<span css={{ ...directionButtonTextStyle }}>GET DIRECTIONS</span>
    // 		</LinkWithH5Support>
    // 	</section>
    // 	{/* temporary replacing this image with a button to get direction..*/}
    // 	{/* because our gmap image api account quota exceeds on high traffic*/}
    // 	{/*<Image*/}
    // 	{/*	src={generateGoogleMapsImageLink({ latitude, longitude })}*/}
    // 	{/*	className={`image image-left image-size-big venue-map`}*/}
    // 	{/*	href={generateGoogleMapsLink({ latitude, longitude })}*/}
    // 	{/*	captionClassName="image-caption"*/}
    // 	{/*	optimize={false}*/}
    // 	{/*	lazy={true}*/}
    // 	{/*/>*/}
    // </section>
    );
};
VenueCard.defaultProps = {
    header: "Venue"
};
export default VenueCard;
const Venuecont = `
	max-width: 840px;
    margin: 45px auto;
	@media screen and ${mediaFeatures['for-mobile-and-small-tablet']} {
		margin: 30px auto;
	}
`;
const titleCont = `
    ${flexBox('space-between', 'center')}
    gap: 16px;
    h6 {
        margin-bottom: 20px;
    }
`;
const venueBodyCont = `
	${flexBox('flex-start', 'flex-start')}
	gap: 30px;
	padding: 18px;
	border-radius: 12px;
	border: 1px solid ${colors['--Grey-Divider']};
	${BackgroundColor('--Background-BG-White')}
	@media screen and ${mediaFeatures['for-mobile-and-small-tablet']} {
		flex-direction: column;
	}
`;
const venueAddress = `
	flex: 1;
	p {
		${getFont('14px', 500)}
		${FontColor('--Grey-Regular-NewCopy')}
	}
`;
const mainText = `
	font-size: 16px !important;
	font-weight: 700 !important;
	margin-bottom: 4px;
`;
const btnCont = `
	${flexBox('center', 'center')}
	gap: 9px;
	${Display}
	${Uppercase}
	padding: 10px 18px 10px 10px;
	border-radius: 6px;
	border: 1px solid ${colors['--Grey-Divider']};
	${BackgroundColor('--Background-BG-White')}
	p {
		${getFont('18px', 700)}
		${FontColor('--Brand-Primary---Navy')}
	}
	img, svg {
		width: 20px;
		height: 20px;
	}
`;
