/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React, { Fragment } from "react";
import { fromJS } from "immutable";
import AboutShell from "../AboutShell";
import EventCountdownTimer from "../EventCountdownTimer";
import Script from "react-load-script";
import EventAdBanner from "../EventAdBanner";
import EventPromoCodeBanner from "../../containers/EventPromoCodeBannerWithSubscription";
import ActiveDiscountOffer from "../../containers/EventDiscountOffers";
import EventHelmet from "../EventHelmet";
import Loader from "../Loader";
import InsiderLoadable from "../InsiderLoadable";
import SummaryCard from "../SummaryCard";
import SummaryCardDetail from "../SummaryCardDetail";
import SummaryCardHeader from "../SummaryCardHeader";
import SummaryCardVisual from "../SummaryCardVisual";
import { Tabs, TabPanel } from "react-tabs";
import { withMediaDesktopOnly } from "../withMediaHoc";
// actions, helpers + selectors
import { fetchEventDetails } from "../../actions/index";
import { getComputedState, CLEVERTAP_ACTIONS, NO_DATA_CLASS, PROMO_BANNER_DATA, getLiveRedirectNotificationMessage, CategoryIdsToSkipTagAndInfo, iOSEventPurchaseConfig, venueConfig, relatedEventConfig, deliveryTrackingCardTagId, addGAEvent, GA_RECOMMENDED_EVENTS } from "../../helpers/constants";
import { isH5Build, sendClevertapEvent } from "../../helpers/misc";
import { getCategoryNameFromResource } from "../../selectors";
import theme, { colors } from "../../styles/common";
import { mediaFeatures } from "../../styles/common";
import { withMediaMobileOnly } from "../withMediaHoc";
import { AsyncMonumentsSpecificBanner } from "../MonumentsSpecificBanner/AsyncMonumentsSpecificBanner";
import { EventAdBannerConfig, ShareButtonsConig, PrerequisitiesConfig, RsvpButtonConfig, EVENT_LEVEL_TRACKING_PIXELS } from "../../helpers/constants";
import EventGuide from "./EventGuide";
import EDPHeaderImageWithVideoEmbed from "../EDPHeaderWithVideo";
import { navigateToPageInApp, hideTitleBarCrossIcon, triggerAppGAAnalytics } from "../../helpers/appBridge";
import queryString from "query-string";
import { stdFontSize } from "../../styles/typography";
import EDPBuyContext from "../../contexts/EDPBuyContext";
import NotFound from "../NotFound";
import _ from "lodash";
import { fetchUserCountry } from "../../helpers/apiClient";
import BottomSheetModal from "../BottomSheetModal";
import { generateEventLink } from "../../helpers/linkGenerators";
import { PrimaryButton } from "../ui-lib/Atoms/Buttons/styles";
import { BaseBold, FontColor, SmallMedium } from "../ui-lib/Atoms/Typography/styles";
// All async imports
const BuyTogetherWithSubscription = InsiderLoadable({
    loader: () => import(/* webpackChunkName: "BuyTogetherComponent" */ "../../containers/BuyTogetherWithSubscription"),
    loading: Loader
});
const Favourite = InsiderLoadable({
    loader: () => import(/* webpackChunkName: "FavouriteComponent" */ "../../containers/FavouriteWithSubscription")
});
const ExitIntent = InsiderLoadable({
    loader: () => import(/* webpackChunkName: "ExitIntentComponent" */ "../ExitIntent")
});
const SummaryCardShare = InsiderLoadable({
    loader: () => import(/* webpackChunkName: "SummaryCardShare" */ "../../containers/EventSummaryCardShareWithSubscription")
});
const RelatedEvents = InsiderLoadable({
    loader: () => import(/* webpackChunkName: "RelatedEvents" */ "../RelatedEvents/RelatedEvents")
});
const pageRegularStyles = `
	padding-top: 2.5vw;
	${theme.mediaQueries["for-tablet-only"]} {
		padding-top: 1.5vw;
	};
	${theme.mediaQueries["for-mobile-and-small-tablet"]} {
		padding-top: 0;
	};
	${theme.mediaQueries["for-pc"]} {
		display: flex;
		flex-direction: row;
	};
`;
const flexColumnPC = `
	${theme.mediaQueries["for-pc"]} {
		display: flex;
		flex-direction: column;
	};
`;
const flexRowTablet = `
	${theme.mediaQueries["for-mobile-and-tablet"]} {
		display: flex;
		flex-direction: row;
	};
`;
const hideOnTablet = `
	section {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
		padding: 30px 0;
	}
	${theme.mediaQueries["for-mobile-and-tablet"]} {
		display: none;
	};
`;
const hideOnDesktop = `
	section {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
		padding: 15px 0 45px;
	}
	${theme.mediaQueries["for-pc"]} {
		display: none;
	};
`;
const TabTitle = `
	font-weight: 700;
	display: inline-block;
	height: 100%;
`;
const favCountStyle = `
	color: ${colors.primaryDark};
 	font-size: 11px;
`;
const Container = `
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 15px;
	flex: 1 0 0;`;
const ReactTabsStyle = `
	width: 50%;
	margin-left: 10vw;
	margin-top: 30px;
	${theme.mediaQueries["for-mobile"]} {
		width: 100%;
		padding: 0 18px;
		margin-left: auto;
		margin-right: auto;
		margin-top: 24px;
	};
	${theme.mediaQueries["for-tablet-only"]} {
		width: 84vw;
		margin: 0 auto;
		margin-left: auto;
		margin-right: auto;
		margin-top: 30px;
	};
	${theme.mediaQueries["for-pc"]} {
		width: 52.5vw;
		margin-left: 10vw;
	}
	${theme.mediaQueries["for-hd"]} {
		margin-left: calc((100vw - 1280px)/2);
   		width: 840px;
	}

	.tab-content {
		margin-top: 30px;
	}

	.react-tabs__tab-list {
		padding: 0;
		border-bottom: 1px solid ${colors["lighter-gray"]};
		display: block;
		position: relative;
		height: 35px;
		white-space: nowrap;
		text-align: initial;
		${theme.mediaQueries["for-mobile"]} {
			height: 28px;
			width: 88vw;
			margin: 0 auto;
		};
	
		li {
			display: inline-block;
			position: relative;
			width: auto;
			height: 100%;
			text-align: center;
			overflow: hidden;
			font-weight: 400;
			cursor: pointer;
			line-height: 60px;
			color: ${colors["slight-light-gray"]};
			margin-right: 30px;
			font-size: 21px;
			line-height: 28px;
			${theme.mediaQueries["for-mobile"]} {
				font-size: ${stdFontSize};
				line-height: 22px;
			};
		}
		.active-tab {
			color: ${colors.headingBlack};
			border-bottom: 2px solid ${colors.black};
		}
	}
`;
const EventSummaryCardDetailWrap = ` 
	display: inline-block;
	vertical-align: top;

	@media screen and ${mediaFeatures["for-large-tablet-only"]} {
		display: inline-block;
	}
	@media screen and ${mediaFeatures["for-mobile"]} {
		display: none;
	}
	@media screen and ${mediaFeatures["for-small-tablet-only"]} {
		display: none;
	}
`;
const getTagListFromEventData = (eventData) => eventData.get("tags", []);
const getPromoData = (tagList) => {
    let tags = Object.keys(PROMO_BANNER_DATA);
    let promoBannerData;
    let tag = tagList.find(element => {
        let currentTagId = element.get("tag_id");
        return tags.includes(currentTagId);
    });
    promoBannerData = tag ? PROMO_BANNER_DATA[tag.get("tag_id")] : false;
    return promoBannerData;
};
const getProductViewedPayload = (eventData, selectedCity, prevPath) => {
    return Object.assign(Object.assign({ "Event ID": eventData.get("_id"), City: selectedCity, Price: eventData.get("price_display_string"), Category: eventData.getIn(["category_id", "name"]), "Category ID": eventData.getIn(["category_id", "id"]), Group: eventData.getIn(["group_id", "name"]), "Group ID": eventData.getIn(["group_id", "id"]), "Venue Tag IDs": eventData.getIn(["venue", "id", "_id"]), "Venue Name": eventData.getIn(["venue", "id", "name"]), "Brand ID": eventData.getIn(["brand_id", "_id"]), "Brand Name": eventData.getIn(["brand_id", "name"]), "Event City": eventData.get("city"), "Event Name": eventData.get("name"), URL: window.location.href, Slug: eventData.get("slug"), "Event State": getComputedState(eventData), "Event Image": eventData.get("horizontal_cover_image") }, (typeof eventData.get("searchable") !== "undefined" ? { Searchable: eventData.get("searchable") } : {})), (typeof prevPath !== "undefined" ? { "Source URL": prevPath } : {}));
};
const hideAdBanner = (eventData) => {
    const HIDDEN_AD_BANNER_EVENT_IDS = EventAdBannerConfig.hideForEventIds;
    const HIDDEN_AD_BANNER_TAG_IDS = EventAdBannerConfig.hideForTagIds;
    const checkForEvents = HIDDEN_AD_BANNER_EVENT_IDS.includes(eventData.get("_id"));
    const checkForTags = getTagListFromEventData(eventData)
        .map(t => t.get("tag_id"))
        .some(tag_id => HIDDEN_AD_BANNER_TAG_IDS.includes(tag_id));
    if (checkForTags || checkForEvents) {
        return true;
    }
    else
        false;
};
export const hideAdBannerForAll = false;
const HIDDEN_PROMO_BANNER_EVENT_IDS = [];
const hidePromoBanner = (id) => HIDDEN_PROMO_BANNER_EVENT_IDS.includes(id);
const hidePromoBannerForAll = false;
const paytmT20MohaliEventId = "5d6e3bc3630cc60008e550af";
const shouldShowPaytmT20Banner = (id) => id === paytmT20MohaliEventId;
const addTrackingPixelForEvent = (trackingPixels, currentEventId) => (<Fragment>
		{trackingPixels
        .filter(trackingPixel => trackingPixel.eventId === currentEventId)
        .map(trackingPixelForCurrenEvent => trackingPixelForCurrenEvent.type === "script" ? (<Script url={trackingPixelForCurrenEvent.trackingImageUrl}/>) : (<img src={trackingPixelForCurrenEvent.trackingImageUrl} border={0} height={0} width={0} alt="Advertisement" className={"advertisement hidden"}/>))}
	</Fragment>);
const getPrerequisiteData = (shows) => {
    let prerequisitesData = [];
    shows.forEach(show => {
        show.get("items_for_sale").forEach(itemGroup => {
            itemGroup.get("items").forEach(item => {
                const prerequisites = item.get("prerequisites");
                if (prerequisites && prerequisites.size > 0) {
                    const primaryPrerequisite = prerequisites.filter(prereq => {
                        return prereq.is_primary;
                    });
                    prerequisitesData.push(primaryPrerequisite.size > 0
                        ? primaryPrerequisite.getIn(["0", "metaData"])
                        : prerequisites.getIn(["0", "metaData"]));
                }
            });
        });
    });
    return prerequisitesData;
};
export const sendProductViewed = ({ eventData, selectedCity, isCleverTapDataSent, updateCleverTapDataSent, sendClevertapEvent, prevPath }) => {
    if (isCleverTapDataSent) {
        return;
    }
    if (!eventData || eventData.get("partial")) {
        return;
    }
    sendClevertapEvent(CLEVERTAP_ACTIONS.PRODUCT_VIEWED, getProductViewedPayload(eventData, selectedCity, prevPath));
    updateCleverTapDataSent(true);
};
export default class EventDetailLayout extends React.Component {
    constructor(props) {
        super(props);
        this.updateCleverTapDataSent = (updatedValue) => {
            if (typeof updatedValue === "boolean") {
                this.isCleverTapDataSent = updatedValue;
            }
        };
        this.getEventData = () => {
            const { eventData, dataReceived, dataRequested, fetchEventDetails, params } = this.props;
            if (dataReceived || dataRequested) {
                return;
            }
            // dont call fetch if eventData already exists, else benifits of ssr would be void.
            // but call it if the data is partial
            const slug = params.slug;
            if (!eventData || eventData.get("partial")) {
                fetchEventDetails({ slug }).catch(e => {
                    // unsure if this is the place to deal with the error
                    console.error(e);
                    const query = queryString.parse(window.location.search);
                    if (!query.reloaded) {
                        // this is to prevent continuous reloading of the page
                        query.reloaded = true;
                        window.location.href = window.location.pathname + "?" + queryString.stringify(query);
                    }
                    else {
                        this.setState({
                            fetchError: true
                        });
                    }
                });
            }
        };
        this.showToastOnRedirectFromLive = (email) => {
            const { redirectedFrom, ticketExpired } = this.props.location.query;
            if (redirectedFrom === "live") {
                if (String(ticketExpired).toLowerCase() === "true") {
                    this.props.notify([
                        {
                            message: "Sorry! Your access to this video has expired.",
                            onRemove: () => {
                                this.props.clearNotifications();
                            },
                            level: "info",
                            position: "tc",
                            autoDismiss: 0
                        }
                    ]);
                }
                else {
                    this.props.notify([
                        {
                            message: getLiveRedirectNotificationMessage(email),
                            onRemove: () => {
                                this.props.clearNotifications();
                                this.props.router.replace("/users/me");
                            },
                            level: "info",
                            position: "tc",
                            autoDismiss: 0
                        }
                    ]);
                }
            }
        };
        this.sendGAItemViewedEvent = (event) => {
            if (!event || event.get("partial") || this.state.gaItemViewdEventTriggered) {
                return;
            }
            const payload = {
                currency: "INR",
                value: 0,
                items: [
                    {
                        item_id: event.get("_id"),
                        item_name: event.get("name"),
                        item_brand: event.getIn(["brand_id", "name"]),
                        item_category: event.getIn(["category_id", "name"])
                    }
                ]
            };
            if (this.props.isFromApp) {
                triggerAppGAAnalytics({ event_name: GA_RECOMMENDED_EVENTS.VIEW_ITEM, payload });
            }
            else {
                addGAEvent(GA_RECOMMENDED_EVENTS.VIEW_ITEM, payload);
            }
            this.setState({ gaItemViewdEventTriggered: true });
        };
        this.getSalientFeature = (eventData, availableShows) => {
            var _a, _b, _c, _d, _e, _f;
            if (!eventData || !availableShows)
                return fromJS([]);
            const eventDataInJs = (_a = eventData === null || eventData === void 0 ? void 0 : eventData.toJS()) !== null && _a !== void 0 ? _a : {};
            const availableShowsInJs = (_b = availableShows === null || availableShows === void 0 ? void 0 : availableShows.toJS()) !== null && _b !== void 0 ? _b : {};
            const extraInfo = (_c = eventDataInJs === null || eventDataInJs === void 0 ? void 0 : eventDataInJs.extra_info) !== null && _c !== void 0 ? _c : [];
            let array = [];
            // get duration of event
            // const durationOfEvent = getDurationOfEvent(availableShowsInJs);
            // if(durationOfEvent?.duration) {
            // 	array.push({
            // 		"icon": <ClockIcon fill="#98989F" />,
            // 		"title": "Duration",
            // 		"type": "duration",
            // 		"text": `${durationOfEvent?.duration}, Starts at ${formatTime(durationOfEvent?.startTime)}`
            // 	})
            // }
            // getting old event guide and only extracting age and language
            // if(extraInfo.length > 0) {
            // 	extraInfo.forEach((item) => {
            // 		const { text = '', title = '' } = item;
            // 		if(title && text && title.toLowerCase() === 'language') {
            // 			array.push({
            // 				...item,
            // 				"icon": <LanguageIcon fill="#98989F" />,
            // 				"type": "language",
            // 			})
            // 		}
            // 		if(title && text && title.toLowerCase() === 'for age(s)') {
            // 			array.push({
            // 				...item,
            // 				"icon": <InfoIcon fill="#98989F" />,
            // 				"type": "age",
            // 			})
            // 		}
            // 	})
            // }
            // getting new event guide from BE and converting it into salient feature
            const components = (_d = eventDataInJs === null || eventDataInJs === void 0 ? void 0 : eventDataInJs.components) !== null && _d !== void 0 ? _d : [];
            const eventGuideFromBE = components.find((component) => component.type === "event_guide" && !component.is_hidden);
            const guideData = (_f = (_e = eventGuideFromBE === null || eventGuideFromBE === void 0 ? void 0 : eventGuideFromBE.content) === null || _e === void 0 ? void 0 : _e.icon_type_heading_subheading) !== null && _f !== void 0 ? _f : [];
            if (guideData.length > 0) {
                const isLanguagePresent = guideData.some(obj => { var _a; return ((_a = obj === null || obj === void 0 ? void 0 : obj.type) !== null && _a !== void 0 ? _a : '').toLowerCase() === 'language'; });
                const isAgePresent = guideData.some(obj => { var _a; return ((_a = obj.type) !== null && _a !== void 0 ? _a : '').toLowerCase() === 'age'; });
                const isDurationPresent = guideData.some(obj => { var _a, _b; return ((_a = obj.type) !== null && _a !== void 0 ? _a : '').toLowerCase() === 'duration' || ((_b = obj.type) !== null && _b !== void 0 ? _b : '').toLowerCase() === 'event_duration'; });
                if (isLanguagePresent) {
                    array = array.filter(obj => { var _a; return ((_a = obj === null || obj === void 0 ? void 0 : obj.type) !== null && _a !== void 0 ? _a : '').toLowerCase() !== 'language'; });
                }
                if (isAgePresent) {
                    array = array.filter(obj => { var _a; return ((_a = obj === null || obj === void 0 ? void 0 : obj.type) !== null && _a !== void 0 ? _a : '').toLowerCase() !== 'age'; });
                }
                if (isDurationPresent) {
                    array = array.filter(obj => { var _a; return ((_a = obj === null || obj === void 0 ? void 0 : obj.type) !== null && _a !== void 0 ? _a : '').toLowerCase() !== 'duration'; });
                }
                guideData.forEach((item) => {
                    const { icon_url, heading, subheading } = item;
                    if (icon_url || heading || subheading) {
                        let obj = Object.assign(Object.assign({}, item), { image_url: icon_url !== null && icon_url !== void 0 ? icon_url : '', title: heading !== null && heading !== void 0 ? heading : '', text: subheading !== null && subheading !== void 0 ? subheading : '' });
                        array.push(obj);
                    }
                });
            }
            return fromJS(array);
        };
        this.showFavouriteCount = (count) => {
            return count && <p css={css(favCountStyle)}>{`${count}`}</p>;
        };
        this.openAppBuyButton = () => {
            return navigateToPageInApp("buyPage", this.props.eventData);
        };
        this.seperator = () => {
            return (<section className="text text-left" css={{
                    height: 1,
                    border: `solid 0.5px ${colors.faintGrey}`,
                    marginTop: "15px",
                    marginBottom: "15px",
                    [theme.mediaQueries["for-mobile"]]: {
                        width: "auto"
                    }
                }}></section>);
        };
        this.checkActivitiesInFeed = async () => {
            const { eventData, isLoggedIn, fetchFeedData } = this.props;
            const eventId = eventData && eventData.get("_id");
            const eventSlug = eventData && eventData.get("slug");
            const data = { eventId };
            try {
                const response = await fetchFeedData(data, isLoggedIn, eventSlug);
                const feedData = response.feed.results;
                if (feedData.length) {
                    this.setState({ isfeedDataAvailable: true });
                }
            }
            catch (e) {
                console.log(e);
            }
        };
        this.reportEventHandler = () => {
            const { eventData, setReportEventData, notify, clearNotifications } = this.props;
            setReportEventData(eventData.get("name"), window.location.href);
        };
        this.eventSummaryCardDetail = (shouldSkipVenueSection = false, shouldSkipReportSection = false, displayMode = "desktop") => {
            const { eventData, isFromApp, queryParams, isLoggedIn, getAvailableShows } = this.props;
            const prerequisites = eventData && eventData.has("venue") && getPrerequisiteData(eventData.getIn(["venue", "shows"]));
            const shouldSkipShareSection = ShareButtonsConig.skipEventIds.includes(eventData.get("_id"));
            const type = eventData && eventData.get("event_type");
            const shouldSkipPrerequisitiesSectionByBrand = PrerequisitiesConfig.skipBrandIds.includes(eventData.getIn(["brand_id", "_id"]));
            const shouldSkipPrerequisitiesSection = shouldSkipPrerequisitiesSectionByBrand || PrerequisitiesConfig.skipEventIds.includes(eventData.get("_id"));
            const shouldShowPrerequisitiesSection = PrerequisitiesConfig.showEventIds.includes(eventData.get("_id"));
            const eventDate = (eventData && eventData.has("venue")) || (eventData && eventData.has("venue_date_string"))
                ? eventData.getIn(["venue", "date_string"]) || eventData.get("venue_date_string")
                : "";
            // const eventDate =
            // 	(eventData && eventData.has("venue")) ? generateEventDateString(eventData.get("venue")?.toJS())?.dateString ?? ''
            // 		: "";
            const shouldDisplayDeliveryTrackerCard = this.shouldDisplayDeliveryTrackerCard(eventData);
            // const getSalientFeature = this.getSalientFeature(eventData, getAvailableShows)
            return (<SummaryCardDetail shellClass={Boolean(eventData) ? "" : NO_DATA_CLASS} title={eventData.get("name")} actionButton={this.showBuyButton("top")} start_utc_timestamp={eventData.get("start_utc_timestamp")} end_utc_timestamp={eventData.get("end_utc_timestamp")} shareButtons={this.showShareButtons()} categoryName={getCategoryNameFromResource(eventData)} standalone event_date={eventDate} venue={eventData.getIn(["venue", "name"]) || eventData.get("venue_name")} city={eventData.get("city")} 
            // salient_features={
            // 	getSalientFeature &&
            // 	getSalientFeature.size > 0 && (
            // 		<SalientFeatures
            // 			salient_features={getSalientFeature}
            // 			venue={eventData.getIn(["venue", "name"]) || eventData.get("venue_name")}
            // 			type={type}
            // 		/>
            // 	)
            // }
            showAsyncFavouriteButton={this.showAsyncFavouriteButton()} prerequisites={prerequisites} type={type} shouldSkipShareSection={shouldSkipShareSection} shouldSkipPrerequisitiesSection={shouldSkipPrerequisitiesSection} shouldShowPrerequisitiesSection={shouldShowPrerequisitiesSection} shouldSkipVenueSection={shouldSkipVenueSection} reportEventHandler={this.reportEventHandler} isFromApp={isFromApp} queryParams={queryParams} shouldSkipReportSection={shouldSkipReportSection} displayMode={displayMode} isLoggedIn={isLoggedIn} shouldDisplayDeliveryTrackerCard={shouldDisplayDeliveryTrackerCard} event_id={eventData.get("_id")}/>);
        };
        this.shouldDisplayDeliveryTrackerCard = (eventData) => {
            return getTagListFromEventData(eventData)
                .map(t => t.get("tag_id"))
                .some(tag_id => tag_id.toString() === deliveryTrackingCardTagId);
        };
        this.state = {
            isfeedDataAvailable: false,
            fetchError: false,
            gaItemViewdEventTriggered: false,
            showGeoRestrictionPopup: false,
            eventAdBannerData: EventAdBannerConfig
        };
        this.isCleverTapDataSent = false;
        this.scrollToTabBar = React.createRef();
        this.internationalRedirectSlug = "";
    }
    static fetchData({ store, params }) {
        return store.dispatch(fetchEventDetails({ slug: params.slug }));
    }
    shouldComponentUpdate(nextProps, nextState) {
        // if event data has changed
        if (this.props.eventData !== nextProps.eventData)
            return true;
        // if event city has changed
        if (this.props.eventCity !== nextProps.eventCity)
            return true;
        // if vwo has changed
        if (this.props.vwo !== nextProps.vwo)
            return true;
        // if slug has changed, i.e, the user has navigated
        // to another event from within the EDP
        if (this.props.params.slug !== nextProps.params.slug)
            return true;
        if (this.props.isLoggedIn !== nextProps.isLoggedIn)
            return true;
        if (this.props.userToken !== nextProps.userToken)
            return true;
        if (this.props.ticketPurchasedStatusReceived !== nextProps.ticketPurchasedStatusReceived)
            return true;
        if (this.state.isfeedDataAvailable !== nextState.isfeedDataAvailable)
            return true;
        if (this.props.relatedEvents !== nextProps.relatedEvents)
            return true;
        if (this.props.eventAdBannerDataReceived !== nextProps.eventAdBannerDataReceived)
            return true;
        if (this.state.fetchError !== nextState.fetchError)
            return true;
        if (this.state.eventAdBannerData !== nextState.eventAdBannerData)
            return true;
        if (this.state.showGeoRestrictionPopup !== nextState.showGeoRestrictionPopup)
            return true;
        return false;
    }
    async componentDidMount() {
        var _a, _b;
        const { eventData, userToken, selectedCity, userProfile, didReceiveLoginData, ticketPurchasedStatusReceived, activityDataReceived, activityDataRequested, fetchToken, checkTicketPurchase, fetchRelatedEvents, fetchEventAdBanner, isFromApp } = this.props;
        this.getEventData();
        if (this.props.isFromApp) {
            this.props.syncUserInfoForPhoenix(false);
            hideTitleBarCrossIcon();
        }
        // Only add this once all the data is in so we know the event id
        if (this.props.eventData && this.props.eventData.get("partial") === false && !isFromApp) {
            this.props.addToRecentlyViewed(this.props.eventData.get("_id"), this.props.eventData);
            fetchRelatedEvents(eventData.get("_id"), selectedCity, eventData.get("slug"));
            fetchEventAdBanner(eventData.get("_id"), selectedCity, eventData.get("slug"));
        }
        this.props.rehydrateReminders();
        this.sendGAItemViewedEvent(eventData);
        sendProductViewed({
            eventData,
            selectedCity,
            isCleverTapDataSent: this.isCleverTapDataSent,
            updateCleverTapDataSent: this.updateCleverTapDataSent,
            sendClevertapEvent,
            prevPath: (_b = (_a = this.props.location) === null || _a === void 0 ? void 0 : _a.state) === null || _b === void 0 ? void 0 : _b.prevPath
        });
        if (didReceiveLoginData) {
            this.showToastOnRedirectFromLive(userProfile.get("email"));
        }
        if (this.props.eventData && this.props.eventData.get("slug")) {
            triggerAppGAAnalytics({
                event_name: GA_RECOMMENDED_EVENTS.SCREEN_VIEW,
                payload: {
                    firebase_screen: `/${eventData.get("slug")}/event`,
                    firebase_screen_class: "EDP"
                }
            });
            let countryDetails = await fetchUserCountry(eventData.get("slug"));
            countryDetails = countryDetails.toJS();
            if (countryDetails.shouldRedirect) {
                this.setState({ showGeoRestrictionPopup: true });
                this.internationalRedirectSlug = countryDetails.internationalSlug;
            }
            // 	const { country } = await fetchUserCountry(eventData.get("slug"));
            // if (country !== "IN") {
            // }
        }
        //TODO: Add after testing
        // if (isH5Build) {
        // 	await this.props.fetchUserInfoForH5()
        // }
        // if (eventData && eventData.get("partial") === false) {
        // 	if(!activityDataRequested && !activityDataReceived){
        // 		this.checkActivitiesInFeed();
        // 	}
        // 	if (!userToken) {
        // 		fetchToken(eventData.get("_id"), isLoggedIn);
        // 	}
        // 	if (isLoggedIn && !ticketPurchasedStatusReceived) {
        // 		checkTicketPurchase(eventData.get("_id"));
        // 	}
        // }
    }
    async componentDidUpdate(prevProps) {
        var _a, _b;
        // This is needed when a related events is browsed through event page,
        // here the component is updated but not unmounted
        const { eventData, params, selectedCity, userProfile, didReceiveLoginData, ticketPurchasedStatusReceived, userToken, activityDataReceived, activityDataRequested, fetchToken, checkTicketPurchase, fetchRelatedEvents, relatedEventsRequested, relatedEventsReceived, fetchEventAdBanner, eventAdBannerDataRequested, eventAdBannerDataReceived, eventAdBanner, isFromApp } = this.props, slug = params.slug;
        this.getEventData();
        if (!prevProps.isFromApp && this.props.isFromApp) {
            this.props.syncUserInfoForPhoenix(false);
            hideTitleBarCrossIcon();
        }
        // Only add this once all the data is in so we know the event id
        if (this.props.eventData && this.props.eventData.get("partial") === false) {
            this.props.addToRecentlyViewed(this.props.eventData.get("_id"), this.props.eventData);
            if (!relatedEventsRequested && !relatedEventsReceived) {
                fetchRelatedEvents(eventData.get("_id"), selectedCity, eventData.get("slug"));
            }
            if (!eventAdBannerDataRequested &&
                !eventAdBannerDataReceived &&
                (isH5Build || (isFromApp && didReceiveLoginData && selectedCity !== prevProps.selectedCity))) {
                fetchEventAdBanner(eventData.get("_id"), selectedCity, eventData.get("slug"));
            }
        }
        if (prevProps.params.slug !== slug) {
            this.isCleverTapDataSent = false;
        }
        this.sendGAItemViewedEvent(eventData);
        sendProductViewed({
            eventData,
            selectedCity,
            isCleverTapDataSent: this.isCleverTapDataSent,
            updateCleverTapDataSent: this.updateCleverTapDataSent,
            sendClevertapEvent,
            prevPath: (_b = (_a = this.props.location) === null || _a === void 0 ? void 0 : _a.state) === null || _b === void 0 ? void 0 : _b.prevPath
        });
        if (!prevProps.didReceiveLoginData && didReceiveLoginData) {
            this.showToastOnRedirectFromLive(userProfile.get("email"));
        }
        if (eventAdBanner && !_.isEqual(prevProps.eventAdBanner, eventAdBanner)) {
            this.setState({
                eventAdBannerData: eventAdBanner.toJS()
            });
        }
        if (this.props.eventData && this.props.eventData.get("slug") !== prevProps.eventData.get("slug")) {
            triggerAppGAAnalytics({
                event_name: GA_RECOMMENDED_EVENTS.SCREEN_VIEW,
                payload: {
                    firebase_screen: `/${eventData.get("slug")}/event`,
                    firebase_screen_class: "EDP"
                }
            });
        }
        if (this.props.eventData && this.props.eventData.get("slug")) {
            let countryDetails = await fetchUserCountry(eventData.get("slug"));
            countryDetails = countryDetails.toJS();
            if (countryDetails.shouldRedirect) {
                this.setState({ showGeoRestrictionPopup: true });
                this.internationalRedirectSlug = countryDetails.internationalSlug;
            }
        }
        // if (eventData && eventData.get("partial") === false) {
        // 	if(!activityDataRequested && !activityDataReceived){
        // 		this.checkActivitiesInFeed();
        // 	}
        // 	if (!userToken) {
        // 		fetchToken(eventData.get("_id"), isLoggedIn);
        // 	}
        // 	if (isLoggedIn && !ticketPurchasedStatusReceived) {
        // 		checkTicketPurchase(eventData.get("_id"));
        // 	}
        // }
    }
    componentWillUnmount() {
        // on unmount, get rid of all the event data
        // later on figure out a cache for this
        // earlier only one event was cleared but now as we have related events too we need to clear all
        this.props.clearEventData();
    }
    showShareButtons() {
        return (<SummaryCardShare shareText={`Check out ${this.props.eventData.get("name")}`} imageUrl={this.props.eventData.get("horizontal_cover_image")} slug={this.props.eventData.get("slug")} _id={this.props.eventData.get("_id")} name={this.props.eventData.get("name")} isEDP isFromApp={this.props.isFromApp}/>);
    }
    showAsyncFavouriteButton() {
        return (<Favourite _id={this.props.eventData.get("_id")} slug={this.props.eventData.get("slug")} eventName={this.props.eventData.get("name")} showButton={true} showFavouriteCount={this.showFavouriteCount} isFromApp={this.props.isFromApp}/>);
    }
    showBuyButton(type, overrideProps) {
        const communication_strategy = this.props.eventData.get("communication_strategy");
        const price_display_string = this.props.eventData.get("price_display_string");
        // const venueDetailsInJs = (this.props.eventData && this.props.eventData.has("venue")) ? this.props.eventData.get("venue")?.toJS() ??[] : [];
        // const price_display_string = this.props.eventData && getPriceString(venueDetailsInJs, this.props.eventData.get("is_free"), this.props.eventData.get("price_display_string"));
        const eventType = this.props.eventData.get("event_type");
        const isFree = this.props.eventData.get("price_display_string", "").toLowerCase() === "free" ||
            this.props.eventData.get("price_display_string", "") === "0" ||
            this.props.eventData.get("is_free");
        // const isFree = typeof price_display_string === 'string' && price_display_string?.toLowerCase() === "free";
        const skipContentBeforeRSVPButton = CategoryIdsToSkipTagAndInfo.skipCategoryIds.includes(this.props.eventData.getIn(["category_id", "id"])) ||
            RsvpButtonConfig.skipEventIds.includes(this.props.eventData.get("_id"));
        const isIOS = this.props.platform === "ios";
        const shouldBlockiOSPurchase = isIOS && iOSEventPurchaseConfig.skipEventIds.includes(this.props.eventData.get("_id"));
        const displayMode = type === "top" ? "desktop" : "mobile";
        return (<EDPBuyContext.Provider value={{ displayMode: displayMode }}>
				<EventCountdownTimer _id={this.props.eventData.get("_id")} show_timer={this.props.eventData.get("show_timer")} timestamp_live={this.props.eventData.get("timestamp_live")} isFromApp={this.props.isFromApp} eventState={getComputedState(this.props.eventData)} name={this.props.eventData.get("name")} slug={this.props.eventData.get("slug")} isRsvp={this.props.eventData.get("is_rsvp")} actionText={this.props.eventData.get("action_button_text")} externalBuyUrl={this.props.eventData.get("external_buy_url")} fetchEventDetails={this.props.fetchEventDetails} communication_strategy={communication_strategy} redirect_url={this.props.eventData.get("redirect_url")} start_utc_timestamp={this.props.eventData.get("start_utc_timestamp")} price_display_string={price_display_string} isFree={isFree} type={type} eventType={eventType} openAppBuyButton={this.openAppBuyButton} skipContentBeforeRSVPButton={skipContentBeforeRSVPButton} eventData={this.props.eventData} selected_city={this.props.selectedCity} queryParams={this.props.location.query} shouldBlockiOSPurchase={shouldBlockiOSPurchase} disableSocialLogin={this.props.disableSocialLogin} platform={this.props.platform} disabled={this.state.showGeoRestrictionPopup} {...overrideProps}/>
			</EDPBuyContext.Provider>);
    }
    render() {
        const { eventData, isLoggedIn, isFromApp, queryParams, recentlyViewedEvents, relatedEvents, selectedCity, searchParams, eventAdBanner, eventAdBannerDataReceived, getAvailableShows } = this.props;
        const { isfeedDataAvailable, fetchError, eventAdBannerData } = this.state;
        const isMonument = eventData && eventData.get("type", "") === "monument";
        let promoData = Boolean(eventData) ? getPromoData(getTagListFromEventData(eventData)) : false;
        const isRemindMeEvent = eventData && eventData.get("communication_strategy") === "reminder";
        const eventDate = (eventData && eventData.has("venue")) || (eventData && eventData.has("venue_date_string"))
            ? eventData.getIn(["venue", "date_string"]) || eventData.get("venue_date_string")
            : "";
        const prerequisites = eventData && eventData.has("venue") && getPrerequisiteData(eventData.getIn(["venue", "shows"]));
        const type = eventData && eventData.get("event_type");
        const isSafetyMeasuresPresent = eventData && eventData.get("safety_measures") && eventData.get("safety_measures").size > 0;
        const eventId = eventData && eventData.get("_id");
        const shouldSkipVenueSection = venueConfig.skipEventIds.includes(eventId);
        // const shouldSkipReportSection = reportEventConfig.skipEventIds.includes(eventId) || disAllowReportSection(eventData);
        const shouldSkipReportSection = true;
        if (fetchError) {
            return <NotFound />;
        }
        if (eventData === undefined) {
            return (<main css={css([greyishBgColor])}>
					<SummaryCard />
					<AboutShell />
				</main>);
        }
        // partial data
        if (eventData.get("partial")) {
            return (<main css={css([greyishBgColor])}>
					<div css={css(pageRegularStyles)}>
						<div css={css(flexColumnPC)}>
							<SummaryCardVisual shellClass={Boolean(eventData) ? "" : NO_DATA_CLASS} headerImageOrVideo={<SummaryCardHeader headerImage={eventData.get("horizontal_cover_image")} title={eventData.get("name")}/>} monumentsBanner={isMonument ? withMediaMobileOnly(AsyncMonumentsSpecificBanner)() : <Fragment />} standalone/>
							<AboutShell />
						</div>
						<div css={css(flexColumnPC)}>
							<SummaryCardDetail shellClass={Boolean(eventData) ? "" : NO_DATA_CLASS} title={eventData.get("name")} actionButton={this.showBuyButton("top", {
                    // override the default way of computing event state
                    // which uses the show info to figure if its expired
                    // We can safely assume that if we have partial data
                    // the event is unlikely to have expired
                    eventState: eventData.get("event_state")
                })} categoryName={getCategoryNameFromResource(eventData)} shareButtons={this.showShareButtons()} standalone event_date={eventDate} venue={eventData.getIn(["venue", "name"]) || eventData.get("venue_name")} city={eventData.get("city")} type={type} shouldSkipVenueSection={shouldSkipVenueSection} reportEventHandler={this.reportEventHandler} isFromApp={isFromApp} queryParams={queryParams} shouldSkipReportSection={shouldSkipReportSection}/>
						</div>
						{this.showBuyButton("bottom", {
                    eventState: eventData.get("event_state")
                })}
					</div>
				</main>);
        }
        const contentComponentsData = eventData.get("components");
        const shouldSkipPrerequisitiesSectionByBrand = PrerequisitiesConfig.skipBrandIds.includes(eventData.getIn(["brand_id", "_id"]));
        const shouldSkipPrerequisitiesSection = shouldSkipPrerequisitiesSectionByBrand || PrerequisitiesConfig.skipEventIds.includes(eventData.get("_id"));
        const shouldShowPrerequisitiesSection = PrerequisitiesConfig.showEventIds.includes(eventData.get("_id"));
        const shouldSkipShareSection = ShareButtonsConig.skipEventIds.includes(eventData.get("_id"));
        const isIOS = this.props.platform === "ios";
        const shouldBlockiOSPurchase = isIOS && iOSEventPurchaseConfig.skipEventIds.includes(eventData.get("_id"));
        const shouldSkipRelatedEvents = relatedEventConfig.skipEventIds.includes(eventData.get("_id"));
        const getSalientFeature = this.getSalientFeature(eventData, getAvailableShows);
        const aboutData = {
            type: "about",
            text: "About",
            component: (<EventGuide eventData={eventData} isMonument={isMonument} isSafetyMeasuresPresent={isSafetyMeasuresPresent} isFromApp={isFromApp} separator={this.seperator()} showShareButtons={this.showShareButtons()} promoData={promoData} shouldSkipShareSection={shouldSkipShareSection} contentComponentsData={contentComponentsData} shouldSkipPrerequisitiesSection={shouldSkipPrerequisitiesSection} shouldShowPrerequisitiesSection={shouldShowPrerequisitiesSection} prerequisites={prerequisites} shouldBlockiOSPurchase={shouldBlockiOSPurchase} type={type} summaryCardDetail={this.eventSummaryCardDetail(shouldSkipVenueSection, shouldSkipReportSection, "mobile")} reportEventHandler={this.reportEventHandler} queryParams={queryParams} searchParams={searchParams} shouldSkipReportSection={shouldSkipReportSection} selectedCity={selectedCity} salientFeature={getSalientFeature}/>)
        };
        // const updatesData = {
        // 	type: "updates",
        // 	text: "Updates",
        // 	component: (
        // 		<ActivityFeed
        // 			isLoggedIn={isLoggedIn}
        // 			eventId={eventId}
        // 			ticketPresent={this.props.ticketPurchasedStatus}
        // 			eventData={eventData}
        // 			ticketDataReceived={this.props.ticketPurchasedStatusReceived}
        // 			scrollToTop={() => window.scrollTo(this.scrollToTabBar.current.offsetTop, 200)}
        // 		/>
        // 	)
        // };
        let tagsObjArray = [aboutData];
        // if (isfeedDataAvailable) {
        // 	tagsObjArray = [...tagsObjArray, updatesData]
        // }
        return (<main css={css([greyishBgColor])}>
				<EventHelmet eventData={eventData}/>
				<div css={css(pageRegularStyles)}>
					{this.state.showGeoRestrictionPopup && (<BottomSheetModal canClose={false}>
							<div css={css(Container)}>
								<p css={css([BaseBold, FontColor("--grey-highlight-copy")])}>Location Restriction</p>
								<p css={css([SmallMedium, FontColor("--grey-regular-copy")])}>
									It looks like you are currently accessing our site from a different country. Head to the event setup
									for your location to get your tickets
								</p>
								<button css={css([PrimaryButton, `margin-top: 15px;`])} onClick={() => {
                    window.location.href = generateEventLink(this.internationalRedirectSlug);
                }}>
									Take me there
								</button>
							</div>
						</BottomSheetModal>)}
					<div css={css(flexColumnPC)}>
						<div css={css(flexRowTablet)}>
							<SummaryCardVisual shellClass={Boolean(eventData) ? "" : NO_DATA_CLASS} headerImageOrVideo={<EDPHeaderImageWithVideoEmbed embedVideo htmlData={eventData.get("custom_html")} headerImageSrc={eventData.get("horizontal_cover_image")} title={eventData.get("name")}/>} monumentsBanner={isMonument ? withMediaMobileOnly(AsyncMonumentsSpecificBanner)() : <Fragment />} standalone/>
							<div css={css(hideOnDesktop)}>
								<div css={css(EventSummaryCardDetailWrap)}>
									{this.eventSummaryCardDetail(shouldSkipVenueSection, shouldSkipReportSection, "tablet")}
								</div>
							</div>
						</div>
						<ActiveDiscountOffer eventSlug={eventData.get("slug")}/>
						{isMonument && withMediaDesktopOnly(AsyncMonumentsSpecificBanner)()}
						{eventAdBannerDataReceived && !isMonument && !hideAdBannerForAll && !hideAdBanner(eventData) && (<EventAdBanner bannerName={eventAdBannerData.campaignName} desktopHref={eventAdBannerData.desktopHref} desktopImageUrl={eventAdBannerData.desktopImageUrl} mobileHref={eventAdBannerData.mobileHref} mobileImageUrl={eventAdBannerData.mobileImageUrl} eventId={eventData.get("_id")} eventName={eventData.get("name")} desktopTrackingImage={EventAdBannerConfig.desktopTrackingImage} mobileTrackingImage={EventAdBannerConfig.mobileTrackingImage} setModalType={false} shouldShowVideoModal={EventAdBannerConfig.shouldShowVideoModal} videoUrl={EventAdBannerConfig.videoUrl} isFromApp={isFromApp} queryParams={queryParams}/>)}
						{addTrackingPixelForEvent(EVENT_LEVEL_TRACKING_PIXELS, eventData.get("_id"))}
						{!hidePromoBannerForAll &&
                shouldShowPaytmT20Banner(eventData.get("_id")) &&
                !hidePromoBanner(eventData.get("_id")) && (<EventPromoCodeBanner lazy={false} slug={eventData.get("slug")}/>)}
						{eventData.has("buy_together") && eventData.get("buy_together").size && (<BuyTogetherWithSubscription eventData={eventData}/>)}

						<Tabs css={css(ReactTabsStyle)} defaultIndex={0}>
							{/* <TabList ref={this.scrollToTabBar} role="navigation">
                {tagsObjArray.map(obj => (
                    <Tab selectedClassName="active-tab" key={obj.type}>
                        <span css={css(TabTitle)}>{obj.text}</span>
                    </Tab>
                ))}
            </TabList> */}
							{tagsObjArray.map(obj => (<TabPanel key={obj.type}>
									{obj.component}
								</TabPanel>))}
						</Tabs>
					</div>
					<div css={css([flexColumnPC, hideOnTablet])}>
						<div css={css(EventSummaryCardDetailWrap)}>
							{this.eventSummaryCardDetail(shouldSkipVenueSection, shouldSkipReportSection, "desktop")}
						</div>
					</div>
					{!shouldSkipRelatedEvents && (<div css={css(hideOnDesktop)}>
							<RelatedEvents relatedEvents={relatedEvents} recentlyViewedEvents={recentlyViewedEvents} city={selectedCity} isFromApp={isFromApp} queryParams={queryParams} eventData={eventData}/>
						</div>)}
					{this.showBuyButton("bottom")}
				</div>
				{eventData.get("exit_intent") && (<ExitIntent cookieName={eventData.get("slug")} exitIntentEmbedSrc={eventData.get("exit_intent")}/>)}
				{!shouldSkipRelatedEvents && (<div css={css(hideOnTablet)}>
						<RelatedEvents relatedEvents={relatedEvents} recentlyViewedEvents={recentlyViewedEvents} city={selectedCity} isFromApp={isFromApp} queryParams={queryParams} eventData={eventData}/>
					</div>)}
			</main>);
    }
}
const greyishBgColor = `
	background: #FAFAFA;
`;
